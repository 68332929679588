.CaisseConciergeOptionCell {
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: -41px;
}
.CaisseConciergeOptionCellImage {
    position: relative;
    margin-left: -10px;
}
.CaisseConciergeSummary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 116px;
    margin-top: 50px;
}
.CaisseConciergeDebours {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}