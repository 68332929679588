.DebourForm {
    width: 460px;
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
}
/*
.ConsigneFormTitle {
    width: 100%;
    min-height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #003671;
    user-select: none;
}
.ConsigneFormClose {
    position: absolute;
    margin-left: 430px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: rgba(0,0,0,.5);
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
    border-radius: 50px;
    user-select: none;
}
.ConsigneFormInputTitle {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 20px 0 10px 0;
    user-select: none;
}
.ConsigneFormCheckbox {
    display: flex;
    margin-top: 20px;
}
.ConsigneFormActions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ConsigneFormActionsBottomGap {
    width: 100%;
    min-height: 20px;
}
*/