.ToDay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.ToDayTop {
    width: 100%;
    display: flex;
    gap: 100px;
}
.ToDayTopChart {
    width: 50%;
}
.ToDayTopChartSelect {
    display: flex;
    justify-content: flex-end;
}
.ToDayTopStats {
    width: 45%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.ToDayTopStatsItem {
    width: 50%;
    height: 60px;
}
.ToDayTopStatsItemTitle {
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0,0,0,.7);
}
.ToDayTopStatsItemValue {
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0,0,0,.7);
}
.ToDayBottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.ToDayBottomTitle {
    width: calc(100% - 20px);
    height: 40px;
    background-color: #1D62AF;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: rgba(255,255,255,1);
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 20px;
}
.ToDayStatsTable {
    display: flex;
    flex-direction: column;
}
.ToDayStatsTableHeadRow {
    display: flex;
}
.ToDayStatsTableRow {
    display: flex;
    border-top: 1px solid rgba(0,0,0,.1);
}
.ToDayStatsTableCell {
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #000;
}
.ToDayStatsTableHeadCell {
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}
.ToDayStatsTableLeftHeadCell {
    width: calc(20% - 20px);
    height: 40px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}