.GnzPaper {
    max-width: 1200px;
    height: 100%;
    width: calc(100% - 80px);
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    flex-grow: 1;
}