.BottomNav {
    width: 100vw;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    overflow: scroll;
}
.BottomNav::-webkit-scrollbar {
    display: none;
}
.BottomNavLeftMenu {
    width: 120px;
    height: 100%;
}
.BottomNavMainMenu {
    display: flex;
}
.BottomNavRightMenu {
    width: 120px;
    height: 100%;
}
.BottomNavMenu {
    display: flex;
    width: 100px;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    text-decoration: none;
}
.BottomNavMenuPicture {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    max-height: 20px;
    margin-top: 10px;
    cursor: pointer;
}
.BottomNavMenuPictureImageTo {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageTo {
    width: 20px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageChecklist {
    width: 30px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageCaisse {
    width: 30px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageVoiturier {
    width: 50px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageRooming {
    width: 50px;
    margin-bottom: 10px;
}
.BottomNavMenuPictureImageWhatsapp {
    width: 40px;
}
.BottomNavMenuPictureImageBreakfast {
    width: 30px;
    margin-bottom: 10px;
}
.BottomNavMenuName {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #003671;
}
.BottomNavMenuNameActive {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E27918;
}
@media (max-width: 992px) {
    .BottomNavMenuName {
        font-weight: 500 !important;
    }
}
