.ProfilClientFormActions{
    display: flex;
    align-self: center;
    margin-top: auto;
}

.ProfilClientButtonTout{
    margin-right: 10px;
    background-color: white;
    color: black;
    border: 1px groove black;
}

.formTag{
    display: flex;
    align-self: center;
    margin-top: auto; 
}
.formCreditcard{
    width: 460px;
    padding: 5vh 8vh 5vh 8vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 25%;
    outline: 0;
    right: 30%;
    background-color: white;
}

.Tag_content{
    background-color: white;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}

.SaveStyle{
    display: flex;
  align-self: center;
  margin-top: 15%;
}

.TagsLine{
    display: ruby;
    width: 153px;
    font-size: small;
    margin-top: 3%;
}

.TagsTitle{
    margin-top: 10%;
}

.TagsTitle::after {
    content: "";
    display: block;
    border-bottom: 1px solid black; /* Puedes ajustar el color y grosor */
    margin-top: 0px; /* Ajusta la distancia entre el texto y la línea */
  }
.TagImg{
    height: 15px;
    margin-left: 1%;
}
