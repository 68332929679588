.GnzBreadcrumb {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GnzBreadcrumbLink {
    user-select: none;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #0B4B92;
}
.GnzBreadcrumbTypo {
    user-select: none;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #0B4B92;
    text-decoration: underline;
}
.GnzBreadcrumbSeparator {
    height: 1px;
    background-color: #0B4B92;
    flex-grow: 1;
}