.filters-container {
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 600px;
    font-family: 'Montserrat', sans-serif;
}

.filters-title {
    min-height: 30px;
    font-weight: 600;
    font-size: 18px;
    color: #003671;
    user-select: none;
}





.ReservationFilters{
    height: 100%;
    padding: 0.8rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
}

.ReservationsFiltersClose {
    width: 1.3rem;
    height: auto;
    cursor: pointer;
}

.ReservationsFiltersClose:hover{
    color: rgb(11, 75, 146);
}

.filters-groups{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ReservationsClearFilters, .ReservationFilters .GnzButton{
    max-width: 100%; 
    height: auto!important;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    background-color: rgb(11, 75, 146);
    cursor: pointer;
    border-radius: 0.3rem;
    padding: 0.5rem 0 0.5rem 0.3rem;
}

.filter-group{
    width: 100%;
    max-width: 100%;
}

.filter-group .GnzButton{
    width: 100%;
}

.filter-group h3{
    margin: 0;
}