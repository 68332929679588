.feedback_payment{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    width: 80%;
    margin-bottom: 8vh;
    padding: 0 0 0 10vw;
}

.feedback_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.TagLogo{
    padding: 4vh 3vh 4vh 0vh;
}

.btnstyle{
    margin-left: 4%;
    height: 35px;
}

.table_thead1{
    color: #0B4B92;
    padding-bottom: 4%;
}

.feedback_content{    
    background-color: white;
    padding: 3vh 5vh 2vh 5vh;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}

.feedback_info{
    padding: 5vh 5vh 5vh 5vh;
    display: inline-flex;
    border-style: groove;
    background-color: #EEEEEE;
    width: 100%;
}

.ProfilClientsDetailsTopActions{
    padding-bottom: 2%;
    padding-left: 0%;
    padding-top: 0%;
    display: -webkit-box;
 }

.feedback_tags{
    display: inline-flex;
    position: absolute;
    bottom: 0;
}
._tags{
    display: inline-flex;
}

.user_tags{
      
    margin-left: 0%;
    margin-right: 8%;
}

.feedback_element{
    padding: 0vh 5vh 0vh 5vh;
}

.moreContent{
    /* bottom: 0; */
    display: block;
    width: 100%;
}

.moreContent1{
    /* bottom: 0; */
    width: 100%;
}


.moreContent_style{
    display: flex;
}

.user_payment .card_element{
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 0.8vw;
    cursor: pointer; 

}

.DataDetailsSyle{
    box-sizing: border-box;
    width: 160px;
    height: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
 }

 .selectSyle{  
    width: 150px;
    min-height: 25px;
    height: 38px;
    border-style: none;  
    margin-right: 1%;      
    border: 2px solid rgba(41, 45, 50, 0.2);
 }

 .selectSyleDiv{      
    border-style: none;  
    display: flex;
    padding-left: 0.5%;
 }
 
 .ProfilClientsDetailsTopActionsh3{
    padding-left: 18%;
    padding-right: 5%;
    margin: 0%;    
 }

 .ProfilClientsItineraireTopActions{
    padding-bottom: 2%;
    padding-top: 2%;
    display: -webkit-box;   
 }

 .layoutDetails{
   display: contents;
 }

 .ProfilClientsItineraireTopActionsh3{
    padding-right: 18%;
    margin: 0%;    
 }

 .inputItinerairesyle{ 
    border-style: none;  
    border-radius: 6px; 
    text-align: center;  
    width: 200px;
    height: 30px;    
    border: 2px solid rgba(41, 45, 50, 0.2);    
 }

 .inputItineraireDivSyle{ 
    padding-right: 10%;
    float: right;
 }

 .DataItinerairesyle{
    box-sizing: border-box;
    width: 81px;
    height: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
 }

 .selectItineraireSyle{
    width: auto;   
    min-height: 25px;
    height: 38px;
    border-style: none;  
    margin-right: 1%;      
    border: 2px solid rgba(41, 45, 50, 0.2);
    background-color: white;
 }

 .selectItineraireSyleDiv{      
    border-style: none;  
    display: flex;
    padding-left: 55%;
    margin-right: 0px;
 }

 .userItineraireItineraire_payment{
   display: flex;
   flex-direction: column;
   justify-content: center;  
   width: 80%;
   margin-bottom: 8vh;
   padding: 0 0 0 10vw;
}

.userItineraireItineraire_header{
   background-color: #0B4B92;
   color: rgba(255,255,255,.48);
   padding: 2vh 3vw 2vh 3vw;
   margin: 0;
   border-top-left-radius: 13px;
   border-top-right-radius: 13px;
   font-size: 1vw;
}

.TagItineraireLogo{
   padding: 4vh 3vh 4vh 0vh;
}

.btnItinerairestyle{
   margin-left: 4%;
   height: 35px;
}

.userItineraireItineraire_content{    
   background-color: white;
   padding: 4vh 10vh 2vh 10vh;
   border-bottom-left-radius: 13px;
   border-bottom-right-radius: 13px;
}

.userItineraireItineraire_info{
   padding: 5vh 5vh 5vh 5vh;
   display: inline-flex;
   border-style: groove;
   background-color: #EEEEEE;
   width: 100%;
}

.ProfilClientsDetailsTopItineraireActions{
   padding-bottom: 2%;
   padding-left: 0%;
   padding-top: 0%;
   display: -webkit-box;
}

.userItineraireItineraire_tags{
   display: inline-flex;
   position: absolute;
   bottom: 0;
}
._tagsItineraire{
   display: inline-flex;
}

.user_tagsItineraire{
     
   margin-left: 0%;
   margin-right: 8%;
}

.userItineraire_elementItineraire{
   padding: 0vh 5vh 0vh 5vh;
}

.moreContentItineraire{
   display: block;
   width: 100%;
}

.moreContent1Itineraire{
   width: 100%;
}

.moreContent_styleItineraire{
   display: flex;
}

.DataDetailsItineraireSyle{
   box-sizing: border-box;
   width: 160px;
   height: 35px;
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
}

.selectItineraireSyle{  
   width: 150px;
   min-height: 25px;
   height: 45px;
   border-style: none;  
   margin-right: 1%;      
   border: 2px solid rgba(41, 45, 50, 0.2);
}

.selectSyleItineraireDiv{      
   border-style: none;  
   display: flex;
   padding-left: 0.5%;
   position: absolute;
   right: 100px;
}

.LineaDiv{
   height: 2px;
   border-top: 1px solid gray;
   padding-bottom: 2%;   
   margin: 20px auto 0 auto;
}

.headertrFeedbackSelect{ 
   background-color: #dbe0e6;
   color: black;
   padding: 0vh 3vw 0vh 0vw;
   border-radius: 50px;
   margin: 0;
   font-size: small;
   height: 30px;
}

.headertrNotSelect{
   background-color: gray;
}

.tableFeedback_data{
   text-align: left;
   color: black;
   width: 200px;
}

.tableFeedback_title{
   text-align: left;
   color: blue;
   width: 200px;
}

.btnstyleconfirmation{
   background-color: #A0E295;
   color: black;
   margin-left: 4%;
   width: 300px;

}.btnstyleItinerarie{
   margin-left: 4%;
   background-color: #F8FD0A ;
   color: red;   
   width: 250px;
}

.ProfilClientsItinerarieActions{
   padding-bottom: 2%;
   padding-left: 0%;
   padding-top: 5%;
   display: inline-flex;
}

.tableXAnnuler_data{
  padding-top: 2%;
  color: red;

}

.tableAnnuler_data{
     padding-top: 2%;
     color: black;
     text-decoration: underline;   
   }

   .itineraire_table{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 8vh;
  }
  
  .itineraire_header{
      background-color: #1D62AF;
      color: rgba(255,255,255,.48);
      padding: 2vh 3vw 2vh 3vw;
      margin: 0 50px;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      font-size: 1vw;
  }
  
  .itineraire_table table{
      background-color: white;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      border-collapse: collapse;
      font-size: 0.8vw;
  }
  
  .itineraire_table .link{
      cursor: pointer;
  }
  .itineraire_table td{
      padding: 1vh 3vw 2vh 3vw;     
  }
  
  .itineraire_table tr:first-child td{
      font-weight: 600;
  }
  
  .itineraire_table .table_data{
      text-align: center;
      color: rgb(119, 119, 119);
  }
    
  .itineraire_table td:last-child:hover{
      text-decoration: underline;
      color: #003671;
  }
  
  .itineraire_table tbody > tr:nth-child(-n+2) > td:nth-last-child(-n+1):hover{
      text-decoration: none;
      margin: 0 50px;
  }
  
  .itineraire_table tbody > tr:nth-child(-n+2) > td:nth-last-child(-n+1):hover{
      text-decoration: none;
  }
  
  .itineraire_table tr:last-child td{
      border-bottom: none;
  }
  
  .divFeedBack2{
    width: 100%;  
    float:left;
  }
 