.CancelResaForm {
    display: flex;
    flex-direction: column;
}
.CancelResaFormLabel {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,.6);
    margin-bottom: 20px;
}