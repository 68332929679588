.CheckListTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CheckListTableContainer {
    width: 100%;
    height: 100%;
    box-shadow: none !important;
}
.CheckListTableHeadCell {
    width: 25%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #0B4B92 !important;
    border-bottom: 0px !important;
    padding-top: 0 !important;
}
.CheckListTableHeadCellDescription {
    width: 40%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #0B4B92 !important;
    border-bottom: 0px !important;
    padding-top: 0 !important;
}
.CheckListTableHeadCellAction {
    width: 10%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #0B4B92 !important;
    border-bottom: 0px !important;
    padding-top: 0 !important;
}
.CheckListTableBottomActions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}
.CheckListTableBottomBest {
    width: 100%;
    height: 20px;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #000;
    text-align: left;
    user-select: none;
}
.RowCellSchedule {
    display: flex;
    height: 40px !important;
    border-bottom: 0px !important;
    padding: 16px 0 0 16px !important;
}
.RowCell {
    display: flex;
    height: 40px !important;
    border-bottom: 0px !important;
    padding: 16px 0 0 0 !important;
}
.RowCellArrow {
    display: flex;
    height: 40px !important;
    border-bottom: 0px !important;
    padding: 16px 16px 0 0 !important;
}
.RowCellScheduleContent {
    width: calc(100% - 16px);
    height: 100%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    background-color: rgba(217, 217, 217, .5);
    border-radius: 100px 0 0 100px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}
.RowCellContent {
    width: calc(100% - 16px);
    height: 100%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    background-color: rgba(217, 217, 217, .5);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}
.RowCellArrowContent {
    width: calc(100% - 16px);
    height: 100%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    background-color: rgba(217, 217, 217, .5);
    border-radius: 0 100px 100px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}
.HiddenTableCell {
    border-bottom: 0px !important;
}
.MissionRow {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}
.MissionRowImage {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.MissionRowInfo {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}
.MissionActions {
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #E7EBED;
    display: flex;
    padding-top: 20px;
    gap: 20px;
}
