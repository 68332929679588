.form-container {
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 600px;
    font-family: 'Montserrat', sans-serif;
}
.form-title {
    min-height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #003671;
    user-select: none;
}
.input-title {
    color: #8b8b8b;
    font-size: 1rem;
    margin-right: 10px; 
    font-weight: bold;
}
.date-picker, .time-picker{
    height: 2.6rem;
    min-height: 1.4375em;
    width: 100%;
}
.time-picker{

}






.ResaFormClose {
    position: absolute;
    margin-left: 430px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: rgba(0,0,0,.5);
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
    border-radius: 50px;
    user-select: none;
}
.ResaFormInputTitle {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 10px 0 10px 0;
    user-select: none;
}
.ResaFormCheckbox {
    display: flex;
    margin-top: 20px;
}
.ResaFormActions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ResaFormActionsBottomGap {
    width: 100%;
    min-height: 20px;
}

.ResaFormGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.ant-picker-dropdown{
    z-index: 1300;
}


.special-wish-input, .AppFormInput, select, .MuiNumberInput-input, .multi-select, .css-1vuhyzt {
    border: 1px solid #E0E3E7;
    height: 2.375rem;
    max-width: 100%;
    box-shadow: 0px 2px 2px #F3F6F9;
    border-radius: 0.4rem;
    margin: 0;
    font-size: 0.9rem;
    font-family: 'Montserrat', sans-serif;
    background-color: white; 
    padding: 0 0 0 1rem;
}
.input-group, .MuiInputBase-root{
    width: 100%;
}