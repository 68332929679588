.ReservationTable{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

.ResaTable {
    width: 100%;
    display: flex;
    flex-direction: row;
}

thead tr th{
    background-color: rgb(29, 98, 175)!important;
}

.ResaTableItem {
    width: 70%;
    display: flex;
    flex-direction: column;

}

.ResaTableItemLeft{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.ResaTableRow {
    display: flex;
    min-height: 50px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.ResaTableHistoryRow {
    display: flex;
    height: 40px;
}
.ResaTableRowCell {
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #003671;
}
.RowActions {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}
.EstablishementTableContainer {
    overflow: auto;
    margin-bottom: 50px;
    width: 100%;
}

.RowDescription {
    text-transform: capitalize;
}

#breakfast-table .PrimaryHeader{
    width: 100%;
    min-width: 100%;
}

#breakfast-table .PrimaryHeaderLogo{
    width: 24px;
    height: 24px;
}

.details_link p{
    cursor: pointer;
}
.details_link p:hover{
    color: rgb(29, 98, 175);
}

#breakfast-table {
    width: 100%;
    flex-direction: column;
    width: 100%;
    margin: 2% 5%;
}

#breakfast-table .ConsignesTopActions {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin: 2% 5%;
    margin-bottom: 20px;
}

#breakfast-table .ConsignesBottomActions {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    margin: 2% 5%;
}