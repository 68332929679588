.PreferenceForm{
    width: 600px;
    /* height: calc(100% - 40px); */
    padding: 2vh 5vh 8vh 8vh;
    display: flex;
    flex-direction: column;
}        

.spaceTop{
    padding-top: 3%;
    padding-bottom: 3%;
}
.ProfilClientPreferenceFormActions{
    display: flex;
    align-self: center;
    margin-top: auto;

}
.FormActionsLefth{
    padding-right: 25px;
}

.FormActionsRight{
    padding-left: 25px;
}

.TagsLine{
    display: ruby;
    width: 153px;
    font-size: small;
    margin-top: 3%;
}

.TagsTitle{
    margin-top: 10%;
}

.TagsTitle::after {
    content: "";
    display: block;
    border-bottom: 1px solid black; /* Puedes ajustar el color y grosor */
    margin-top: 0px; /* Ajusta la distancia entre el texto y la línea */
  }
.TagImg{
    height: 15px;
    margin-left: 1%;
}