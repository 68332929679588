.MssAdminDepartment {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.MssAdminDepartmentName {
    width: calc(100% - 30px);
    height: calc(40px - 20px);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,54,113,0.8);
    padding: 10px 15px;
    background-color: #F4F4F4;
    border-radius: 5px;
    user-select: none;
}
.MssAdminDepartmentUsers {
    display: none;
    flex-direction: column;
    gap: 20px;
}
.MssAdminDepartmentUsersVisible {
    display: flex;
}