.MssAdminUsers {
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}
.MssAdminUsersHeader {
    width: calc(100% - 40px);
    min-height: calc(50px - 20px);
    display: flex;
    padding: 10px 20px;
    gap: 10px;
}
.MssAdminUsersHeaderLogo {
    display: flex;
    align-items: center;
}
.MssAdminUsersHeaderName {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #1D62AF;
    user-select: none;
}
.MssAdminUsersBody {
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #E8E8E8;
    border-radius: 100px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(122,122,122,.7);
    border-radius: 100px;
}
  
