.ClienteHeader {
    max-width: 1320px;
    width: 80%;
    min-height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 9%;
}
.ClienteHeaderLogo {
    user-select: none;
}
.ClienteHeaderTitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #0B4B92;
    padding: 0 30px;
    user-select: none;
}
.ClienteHeaderSeparator {
    height: 1px;
    background-color: #0B4B92;
    flex-grow: 1;
}