.restau-calendar-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* max-width: 400px; */
  /* max-height: 90vh; */
  width: 70%;
  height: 85vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
}

.restau-calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.restau-calendar-header h1 {
  text-align: center;
  font-weight: 700;
  font-size: 23px;
  color: #1d62af;
  padding-bottom: 10px;
  margin-top: 0;
}

.restau-calendar-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.restau-calendar-filter input {
  height: 7px;
  font-family: "Poppins" "sans-serif" !important;
}

.restau-calendar-filter input[type="month"] {
  font-size: 16px;
  font-family: "Poppins" "sans-serif" !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 0 5px;
  height: 40px;
  color: rgba(0, 0, 0, 0.6);
  width: 210px;
  min-width: 120px;
}

.restau-calendar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.restau-calendar-day {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
}

.restau-calendar-day h1 {
  font-size: 22px;
  margin-left: 7px;
  margin-bottom: 10px;
}

.calendar-day-status {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  width: 97px;
  height: 117px;
  flex-shrink: 0;
}

.calendar-day-status h3 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 8.5px; /* 94.444% */
}

.calendar-day-status-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.restau-calendar-day span {
  font-size: 13px;
  padding: 0 3px;
  text-align: center;
}

.restau-calendar-day-span {
  cursor: pointer;
  background-color: #7f7f7f;
  width: 11px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 7px;
  flex-shrink: 0;
}

.day-status-span {
  cursor: pointer;
  background-color: #7f7f7f;
  width: 11px;
  height: 14px;
  border-radius: 50%;
  flex-shrink: 0;
}

.restau-calendar-day-status-Fermé {
  background-color: rgba(255, 0, 0, 0.2);
}
.restau-calendar-day-span-Fermé {
  background-color: #f00;
}

.restau-calendar-day-status-Privatisé {
  background-color: rgba(0, 209, 255, 0.2);
}
.restau-calendar-day-span-Privatisé {
  background-color: #00d1ff;
}

.restau-calendar-day-status-Groupe {
  background-color: rgba(149, 226, 175, 0.2);
}
.restau-calendar-day-span-Groupe {
  background-color: #95e2af;
}

.restau-calendar-day-status-Complet {
  background-color: rgba(255, 168, 0, 0.2);
}
.restau-calendar-day-span-Complet {
  background-color: #ffa800;
}

.table-time-slots-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 40vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  padding-bottom: 0;
  margin-left: 50px;
  margin-bottom: 10px;
}

.table-time-slots-modal h2 {
  color: #000;
  font-family: "Poppins" "sans-serif" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 200% */
  letter-spacing: -0.27px;
  margin: 0;
}

.table-time-slots-modal p {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins" "sans-serif" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 257.143% */
  letter-spacing: -0.21px;
  margin: 0;
}

.time-slots-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.time-slots-container-key {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: max-content;
}

.time-slots-container-key h3 {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins" "sans-serif" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 240% */
  letter-spacing: -0.225px;
}

.table-time-slot {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.table-time-slot span {
  width: 24px;
  height: 54px;
  border-radius: 4px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  background: #a0e295;
}

.table-time-slot h3 {
  color: #000;
  font-family: "Poppins" "sans-serif" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 200% */
  letter-spacing: -0.27px;
  margin: 0;
}

.table-time-slot-available span {
  background: #a0e295;
}

.table-time-slot-full span {
  background: #f91f1f;
}

.table-time-slot-few span {
  background: #f9951f;
}

.time-slots-container-key-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}

.time-slots-container-key-column span {
  width: 24px;
  height: 26px;
  border-radius: 4px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  background: #a0e295;
  margin-right: 5px;
}

@media (max-width: 900px) {
  .restau-calendar-filter {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 650px) {
  .restau-calendar-day {
    width: 90px;
  }
  .restau-calendar-filter {
    flex-direction: column;
  }
  .table-time-slots-modal {
    width: 77%;
    margin-left: 10px;
    height: fit-content;
  }
  .table-time-slot {
    margin-right: 5px;
  }
}
