.restau-section {
  display: flex;
  flex-direction: column;
  margin: 2% 5%;
}

.restau-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.restau-form {
  /* width: 1150px; */
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
}

.restau-form-header h1 {
  border-bottom: none !important;
}

.restau-form h1 {
  font-size: 22px;
  font-weight: 700;
  color: #003671;
  padding-bottom: 10px;
  border-bottom: 0.5px groove rgba(0, 0, 0, 0.2);
  margin-top: 0;
}

.restau-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;

  color: #101010;
  font-style: normal;
  font-size: 13px;
  /* line-height: 36px; */
  font-weight: 600;
}

.restau-column .label-restau {
  margin-top: 10px;
}

.restau-column .text-restau {
  width: 98%;
  padding: 2px 0px 2px 7px;
  font-size: 15px;
  /* font-weight: 600; */
  line-height: 30px;
  color: #000;
  opacity: 0.8;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 5px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif !important;
}

.restau-column2 {
  display: flex;
  flex-direction: row;
}

.restau-column #btnDiv button {
  color: #000;
  border: 1px #000 solid;
  border-radius: 5px;
  background-color: #fff;
  width: 48%;
  margin-top: 20px;
  height: 45px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  cursor: pointer;
}

.restau-column button:hover,
.restau-btn-clicked {
  background-color: #003671 !important;
  color: #fff !important;
}

.restau-column .custom-radio:checked {
  color: #1dc9a0 !important;
}

.css-hyxlzm {
  color: #1dc9a0 !important;
}

.restau-column3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.restau-column3 .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.restau-column3 .row .column {
  width: 30%;
}

.restau-column3 .switch-column {
  width: 30%;
  display: flex;
  justify-content: center;
}

.restau-column3 .color-pallete {
  height: 35px;
  cursor: pointer;
  border: none;
  background-color: #fff;
}

.restau-column3 .color-pallete::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
}

.restau-column3 .color-pallete::-moz-color-swatch {
  border: none;
  border-radius: 15px;
}

.restau-btn {
  color: #fff;
  border: 1px #000 solid;
  border-radius: 5px;
  background-color: #003671;
  width: 100%;
  margin-top: 20px;
  height: 45px;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
}

.radio-restau {
  justify-content: space-between;
  flex-wrap: nowrap !important;
}

.radio-restau label {
  border: 0.5px solid #e3e1e1;
  border-radius: 8px;
  padding: 5px;
  padding-left: 0;
  margin-left: 0;
  margin-right: 5px;
  height: 30px;
  padding-right: 3px;
}

/* .custom-radio:checked + label {
    background-color: rgba(29, 201, 160, 0.10) !important;
  } */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root:checked + .MuiTypography-root {
  background-color: rgba(29, 201, 160, 0.1);
}

.radio-restau span {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
}

.restau-column input[type="date"],
.restau-column input[type="time"] {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
}

.color-select-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  padding: 5px;
}

.color-display {
  width: 30px;
  height: 20px;
  border-right: 1px solid #ccc;
  border-radius: 5px;
}

.color-select {
  appearance: none;
  border: none;
  padding: 5px;
  margin-left: -5px;
  flex-grow: 1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.color-select-arrow {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: gray;
  margin-left: 5px;
  transform: rotate(90deg);
}

.restau-color-option {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
}

.restau-add-btn {
  border: none;
  width: 37px;
  height: 37px;
  border-radius: 10px;
  background-color: #003671;
  color: white;
  font-size: 17px;
  cursor: pointer;
}

.restau-submit-div {
  display: flex;
  justify-content: flex-end;
}

.restau-btn-submit {
  width: 30%;
}

@media (max-width: 1100px) {
  .restau-form {
    padding: 30px 20px;
  }
}

@media (max-width: 1000px) {
  .restau-main-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .restau-column {
    min-width: 230px;
    width: 40%;
  }
}

@media (max-width: 650px) {
  .restau-column {
    width: 100%;
  }

  .restau-submit-div {
    justify-content: center;
  }

  .restau-btn-submit {
    width: 60%;
  }

  .radio-restau {
    flex-wrap: wrap !important;
  }
}
