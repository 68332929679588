.center-horizonally{
    display: flex;
    justify-content: center;
  }
  .center-left{
    display: flex;
    justify-content: left;
  }

  .service-reservation-btn {
    background-color: #a0e295;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #1d62af;
    cursor: pointer;
    font-family: "Poppins", sans-serif !important;
  }
  
  .service-filter-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
   