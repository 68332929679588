.GnzButton {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 6px 18px;
    border: none;
}

.GnzButton:hover {
background-color: #EBB21F ;
}
.GnzButtonLabel {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    user-select: none;
}
