.PrimaryNav {
    width: 100%;
    min-height: 140px;
    background-color: #fff;
    height: auto;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PrimaryNav::-webkit-scrollbar {
    display: none;
}
.PrimaryNavItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    min-height: 100px;
    padding: 0 10px;
    background-color: #F0F4F8;
    border-radius: 10px;
    color: #235d9d;
    cursor: pointer;
    margin: 0 10px;
}
.PrimaryNavItem:first-child {
    margin-left: 10px;
}
.PrimaryNavItemLabel {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin-top: 10px;
}
.PrimaryNavItem:hover {
    background-color: rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
    .PrimaryNavItem:hover {
        background-color: #F0F4F8;
    }
}

.PrimaryNavMenu {
    display: flex;
    width: 120px;
    height: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}
.PrimaryNavMenu:hover {
    cursor: pointer;
}
.PrimaryNavMenuPicture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #1D62AF;
    margin-bottom: 10px;
    border-radius: 50px;
}
.PrimaryNavMenuPicture:hover {
    background-color : #fff; 
}
.PrimaryNavMenuPictureCurrent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #A0E295;
    margin-bottom: 10px;
    border-radius: 50px;
}
.PrimaryNavMenuName {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D62AF;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
}
.PrimaryNavMenuNameCurrent {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #003671;
    padding-bottom: 5px;
    border-bottom: 3px solid #003671;
}
.PrimaryNavDropdownMenuBackDrop {
    display: none;
}
.PrimaryNavDropdownMenu {
    z-index: 1;
    position: fixed;
    top: 220px;
    width: 180px;
    background-color: #fff;
    border-radius: 10px 10px;
    box-shadow: 0px 5px 5px #cbcbcb;
    padding: 10px;
}
.PrimaryNavDropdownMenuHeader {
    display: none;
}
.PrimaryNavDropdownMenuItem {
    width: calc(100% - 20px);
    height: 40px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    user-select: none;
}
.PrimaryNavDropdownMenuItem:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}
.PrimaryNavDropdownSubMenu {
    z-index: 2;
    position: absolute;
    margin-top: 40px;
    margin-left: 190px;
    min-width: 150px;
    background-color: #fff;
    border-radius: 10px 10px;
    box-shadow: 0px 5px 5px #cbcbcb;
    padding: 10px;
}
.PrimaryNavDropdownSubMenuItem {
    width: calc(100% - 20px);
    height: 40px;
    font-family: 'Robotto', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    user-select: none;
}
.PrimaryNavDropdownSubMenuItem:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}
.PrimaryNavDropdownSubSubMenu {
    z-index: 3;
    position: absolute;
    margin-top: 120px;
    margin-left: 160px;
    min-width: 230px;
    background-color: #fff;
    border-radius: 10px 10px;
    box-shadow: 0px 5px 5px #cbcbcb;
    padding: 10px;
}
.PrimaryNavDropdownSubSubMenuItem {
    width: calc(100% - 20px);
    height: 40px;
    font-family: 'Robotto', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    user-select: none;
}
.PrimaryNavDropdownSubSubMenuItem:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}
@media (max-width: 768px) {
    .PrimaryNavDropdownMenuBackDrop {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 70px;
    }
    .PrimaryNavDropdownMenu {
        top: 210px;
        left: 0;
        width: calc(100vw - 20px);
        height: calc(100vh - 70px - 140px - 140px - 20px);
        background-color: #f0f4f8;
        box-shadow: none;
        border-radius: 0;
    }
    .PrimaryNavDropdownMenuHeader {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .PrimaryNavDropdownSubMenu {
        position: fixed;
        margin-top: 0;
        margin-left: 0;
        top: 210px;
        left: 0;
        width: calc(100vw - 20px);
        height: calc(100vh - 70px - 140px - 140px - 20px);
        background-color: #f0f4f8;
        box-shadow: none;
        border-radius: 0;
    }
    .PrimaryNavDropdownSubMenuHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .PrimaryNavDropdownSubSubMenu {
        position: fixed;
        margin-top: 0;
        margin-left: 0;
        top: 210px;
        left: 0;
        width: calc(100vw - 20px);
        height: calc(100vh - 70px - 140px - 140px - 20px);
        background-color: #f0f4f8;
        box-shadow: none;
        border-radius: 0;
    }
    .PrimaryNavDropdownSubSubMenuHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}
