.userStays_payment{
    display: block;
}

.userStays_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.userStays_content{
    background-color: white;
    padding: 2vh 3vw 2vh 3vw;
    border-left-style: groove;
    border-right-style: groove;
    border-bottom-style: groove;
}

.userStays_info{
    background-color: white;
    display: inline-flex;
}

.userStays_tags{
    display: inline-flex;
    position: absolute;
    bottom: 0px;
}
.userStays_tag{
    display: inline-flex;
    margin-top: 5%;
}

.userStays_tags{
      
    margin-left: 0%;
    margin-right: 8%;
}

.img_Tag{
    width: 12px;
}


.userStays_element{
    padding: 0vh 5vh 0vh 5vh;
}

/* //////////////////// */


.booking_table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 8vh;
}

.booking_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.booking_table table{
    width: 100%;
    background-color: white;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-collapse: collapse;
    font-size: 0.8vw;
}

.booking_table .link{
    cursor: pointer;
}
.booking_table td{
    padding: 2vh 3vw 2vh 3vw;
    border-bottom: 1px rgb(230, 230, 230) solid;
}

.table_thead{
    color: black;    
}

.booking_table tr:first-child td{
    text-align: center;
    font-weight: 600;
}

.table_data{
    text-align: center;
    color: rgb(119, 119, 119);
}


.booking_table td:last-child:hover{
    text-decoration: underline;
    color: #003671;
}

.booking_table tbody > tr:nth-child(-n+2) > td:nth-last-child(-n+1):hover{
    text-decoration: none;
}

.booking_table tbody > tr:nth-child(-n+2) > td:nth-last-child(-n+1):hover{
    text-decoration: none;
}

.booking_table tr:last-child td{
    border-bottom: none;
}



