.NoMatch {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
}
