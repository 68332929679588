.SignIn {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(rgba(29, 98, 175, 1), rgba(29, 98, 175, .9));
}
.SignInLogo {
  margin-top: 1.5em;
  margin-bottom: 1em;
  color: #fff;
  font-size: 3em;
  font-family: 'Stella Nova', sans-serif;
  user-select: none;
  display: flex;
  flex-grow: 0;
}
.SignInForm {
  width: calc(100% - 60px);
  height: auto;
  padding: 30px;
  border-radius: 5px;
  background-color: #fff;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SignInFormTextInput {
  min-height: 20px;
  width: calc(100% - 30px);
  background-color: rgba(0,0,0,.1);
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,.8);
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 5px;
  user-select: none;
}
.SignInFormPasswordInput {
  min-height: 20px;
  width: calc(100% - 30px);
  background-color: rgba(0,0,0,.1);
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,.8);
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 5px;
  user-select: none;
}
.mt25 {
  margin-top: 25px;
}
.SignInFormButton {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: none;
  margin-top: 25px;
  background-color: rgba(29, 98, 175, 1);
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}
.SignInFormButton:hover {
  cursor: pointer;
}
.SignInFormButton:active {
  opacity: 0.9;
}
@media (max-width: 992px) {
  .SignInLogo {
      flex-grow: 1;
  }
  .SignInForm {
      border-radius: 5px 5px 0 0;
  }
}
.SignInFormVersion {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
}
