.FeedbackForm {
    width: 460px;
    height: calc(100% - 40px);
    padding: 5vh 8vh 20vh 8vh;
    display: flex;
    flex-direction: column;
}

.InputStyle{
    border-style: none;  
    border-radius: 6px; 
    text-align: left;  
    width: 120px;
    height: 30px;    
    border: 2px solid rgba(41, 45, 50, 0.2);
 }

 .ButtonStyle{
    border-style: none;  
    border-radius: 6px; 
    text-align: center;  
    height: 30px;    
    border: 2px solid rgba(41, 45, 50, 0.2);
 }

.FeedbackFormTitle {
    width: 100%;
    min-height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #003671;
    user-select: none;
}
.FeedbackFormClose {
    position: absolute;
    margin-left: 430px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: rgba(0,0,0,.5);
    cursor: pointer;
    border-radius: 50px;
    user-select: none;
}
.FeedbackFormInputTitle {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 20px 0 10px 0;
    user-select: none;
}
.FeedbackFormCheckbox {
    display: flex;
    margin-top: 20px;
}
.FeedbackFormActions {
    width: 100%;
    margin-top: 20px;
    padding: 10vh 0vh 10vh 0vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.FeedbackFormActionsBottomGap {
    width: 100%;
    min-height: 20px;
}

.FeedbackFormSelect{
    width: 450px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

}

.FeedbackFormTextarea{
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.ValiderButton{
    background-color: #A0E295;
    width: 500px;
    height: 30px;
    margin-top: 5%;
    margin-bottom: 5%;
}