.AddCommentForm {
    display: flex;
    flex-direction: column;
    width: 600px;
}
.AddCommentFormContent {
    display: flex;
    flex-direction: column;
}
.AddCommentFormInputTitle {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 20px 0 10px 0;
    user-select: none;
}
.AddCommentFormInputTitle {
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 20px 0 10px 0;
    user-select: none;
}