.ReservationTopActions {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.ReservationTable {
    width: 100%;
    display: flex;
    flex-grow: 1;
}
.ReservationBottomActions {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
}

.PrimaryHeader {
    max-width: 1320px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.PrimaryHeaderLogo {
    user-select: none;
}
.PrimaryHeaderTitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #0B4B92;
    padding: 0 20px;
    user-select: none;
}
.PrimaryHeaderSeparator {
    height: 1.5px;
    background-color: #0B4B92;
    flex-grow: 1;
}

.resa-table-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.resa-table-title h3{
    margin: 0;
}
.resa-table-title svg{
    font-size: 1.5rem;
    margin-right: 1rem;
}

.allergy-modal{ 
    box-shadow: -5px -5px 5px -5px #00000059;
    border-radius: 0.3rem;
    background-color: white;
    padding: 0.5rem;
    z-index: 999;
}

#info-links{
    display: flex;
    flex-direction: row;
}

.modal-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
    gap: 0.3rem;
}
.modal-item img{
    width: 1.5rem;
    height: 1.5rem;
}

.modal-link{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.modal-link p{
    margin: 0;
    font-size: 0.8rem;
}

.table-bottom-btn{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}