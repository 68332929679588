.ReservationTable{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 3rem;
}
.ResaTable {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.ResaTableItem {
    width: 70%;
    display: flex;
    flex-direction: column;

}

.ResaTableItemLeft{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.ResaTableRow {
    display: flex;
    min-height: 50px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.ResaTableHistoryRow {
    display: flex;
    height: 40px;
}
.ResaTableRowCell {
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #003671;
}
.RowActions {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}
.ResaTableContainer {
    height: 400px;
    overflow: auto;
    margin-bottom: 1rem;
    width: 100%;
}

.RowDescription {
    text-transform: capitalize;
}

.sub-row-label{
    color: #003671;
}

.food-icon{
    max-height: 1.6em;
    vertical-align: middle;
    margin-right: 0.3em;
}