.Caisse {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.CaisseBottomActions {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: flex-end;
}
.CaisseBottomCenterActions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.CaisseBottomCenterActionsBlueCircle {
    width: 20px;
    height: 20px;
    background-color: #0B4B92;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.CaisseBottomCenterActionsDot {
    width: 10px;
    height: 10px;
    background-color: #0B4B92;
    border-radius: 50px;
}
.CaisseBottomCenterActionsDotOther {
    width: 10px;
    height: 10px;
    background-color: #0B4B92;
    border-radius: 50px;
    cursor: pointer;
}
.CaisseBottomCenterActionsActiveDot {
    width: 10px;
    height: 10px;
    background-color: #EBB21F;
    border-radius: 50px;
}

.CaisseConcierge {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.CaisseConciergeRow {
    display: flex;
    gap: 10px;
}
.CaisseConciergeLargeGreyCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #003671;
    background-color: rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeLargeYellowCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #003671;
    background-color: #EBB21F;
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeLargeBlueCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
    background-color: #0B4B92;
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeGreenCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #003671;
    background-color: #95E2AF;
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeGreyCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #003671;
    background-color: rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    outline: none;
}
.CaisseConciergeGreyCellLeft {
    width: calc(100% - 10px);
    height: 30px;
    padding-left: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #003671;
    background-color: rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    outline: none;
}
.CaisseConciergeBlueCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
    background-color: #0B4B92;
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeLargeNoCell {
    width: 100%;
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.CaisseConciergeGreyInputCell {
    width: 100% !important;
    height: 30px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    color: #003671 !important;
    background-color: rgba(0,0,0,.1) !important;
    border: 1px solid rgba(0,0,0,.2) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    user-select: none !important;
    outline: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    text-align: center;
}
.CaisseConciergeRowColum {
    width: calc(100% / 3);
    gap: 10px;
    display: flex;
}