body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7ebed;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(201, 224, 253, 0.8);
  -webkit-tap-highlight-color: transparent;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.menu .MuiList-root {
  padding: 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.menu .MuiPaper-root {
  padding: 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Poppins", sans-serif !important;
}

.dotted-line {
  border-right: 2px dotted blue; /* Customize line color and height */
  height: 20px; /* Customize line height */
  width: 100%; /* Set the width to 100% to span the entire container */
}

.organigram {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.test > ul:first-child::before {
  margin-right: 10px !important;
}
.test > ul {
  margin-right: -2px !important;
}

.newdpt input {
  padding: 0;
  margin: 5px !important;
  height: 35px !important;
}
.newdpt .MuiSelect-select {
  padding: 0;
  margin: 5px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio {
  margin-left: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (max-width: 768px) {
  .radio {
    margin-left: 0;
  }
}
.scrollbar-container{
  height: auto !important;
}

.org .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0);
}
.created {
  width: 100%;
}
.editDialog .MuiPaper-root {
  max-width: 1300px;
}

.contract input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.contract .MuiSelect-select {
  padding-top: 10px;
  padding-bottom: 10px;
}
.sum .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
}
.collaborateurs .MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
}
.collaborateurs .col {
  background-color: white;
}
.react-calendar__month-view__days__day.selectedDate {
  background-color: rgba(255, 255, 0,0.4) !important;
}

.react-calendar__month-view__days__day.enAttente {
  background-color: #FFAC4C!important;
}
.react-calendar__month-view__days__day.valide {
  background-color: #A0E295!important;
}
.react-calendar__month-view__days__day.rejeté {
  background-color: #95BDE2!important;
}
.react-calendar {
  width: 100% !important;
  max-width: 900px !important;
  border: none !important;
}
.react-calendar__month-view {
  background-color: #F5F5F5;
  padding: 20px;
      border-radius: 10px !important;

}
.react-calendar__tile {
  height: 80px;
  flex: auto !important;
  width: 13%;
  margin: 0.64285% !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: bold !important;
  font-size: 18px !important;
  display: flex;

}
.react-calendar__navigation__label{
  font-weight: bold;
  font-size: large;
}
.react-calendar__month-view__days__day {
  background-color: white !important;
  border-radius: 20px;
}
textarea {
  width: calc(100% - 30px);
  min-height: 200px;
  background-color: rgba(0,0,0,.1);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0,0,0,.8);
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 5px;
  resize: none;
  overflow: auto;
}
select {
  width: 200px;
  min-height: 50px;
  border: none;
  outline: none;
  background-color: #fff;
  opacity: 0.8;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
}
/*
.ant-picker-dropdown{
  z-index: 1400;
}
.ant-picker-now-btn {
  margin-right: 10px;
}
.SecondaryDatePickerNoMarginBottom > .ant-picker-input > input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0,0,0,.8);
  text-transform: capitalize;
  caret-color: transparent;
}
.ant-picker-suffix, .ant-picker-clear {
  display: none !important;
}
.ant-picker:hover {
  border-color: transparent;
}
.ant-picker-focused {
  box-shadow: none;
}
.SecondaryDatePicker > .ant-picker-input > input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0,0,0,.8);
  text-transform: capitalize;
  caret-color: transparent;
}
.PrimaryClientsDatePicker > .ant-picker-input > input {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500px;
  font-size: 14px;
  text-transform: capitalize;
  caret-color: transparent;
}
.PrimaryDatePicker > .ant-picker-input > input {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
  caret-color: transparent;
}
.ant-picker {
  width: 200px;
  min-height: 50px;
  border: none;
  outline: none;
  background-color: #fff;
  opacity: 0.8;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
}
*/