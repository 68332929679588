.MssAdminUser {
    width: calc(100% - 20px);
    height: calc(65px - 40px);
    background-color: #F0F4F8;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    padding: 20px 10px;
    align-items: center;
}
.MssAdminUser.MssAdminUserSelected {
    background-color: #A0E295;
}
.MssAdminUserPicture {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: #fff;
}
.MssAdminUserNameRole {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
}
.MssAdminUserName {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #000;
    font-size: 12px;
    user-select: none;
}
.MssAdminUserRole {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    user-select: none;
}
.MssAdminUser.MssAdminUserSelected .MssAdminUserRole {
    color: rgba(0,0,0,0.6);
}
.MssAdminUserMoreVertIcon {
    display: flex;
    align-items: center;
}