.restau-form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px groove rgba(0, 0, 0, 0.2);
}

.restau-form-header h1 {
  border-bottom: none;
}
.restau-service-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
  /* padding: 0 5px; */
  width: 97%;
}

.restau-service-time h4 {
  font-weight: 500;
  text-decoration: underline;
  margin: 12px;
}

.restau-add-date h4 {
  font-weight: 500;
  text-decoration: underline;
  margin: 12px;
  color: #003671;
  cursor: pointer;
}

.restau-service-container {
  background-color: #e9faf6;
  border-radius: 10px;
  max-height: 150px;
  overflow-y: auto;
}

.restau-periode-container {
  background-color: #e9faf6;
  border-radius: 10px;
  max-height: 120px;
  overflow-y: auto;
}

.restau-menu-container {
  background-color: #e9faf6;
  border-radius: 10px;
  max-height: 120px;
  overflow-y: auto;
}

.restau-table-container {
  background-color: #e9faf6;
  border-radius: 10px;
  border: none;
  padding: 0;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #a7a5a5;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 6px;
}
