.modal-restau {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* max-width: 400px; */
  /* max-height: 90vh; */
  width: 30%;
  height: 85vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  outline: none;
  /* justify-content: space-between; */
}

.modal-restau h1 {
  text-align: center;
  font-weight: 700;
  font-size: 23px;
  color: #003671;
  padding-bottom: 10px;
  margin-top: 0;
  border-bottom: 0.5px groove rgba(0, 0, 0, 0.2);
}

.modal-restau h2 {
  margin: 5px;
  font-size: 14px;
}

.modal-restau h3 {
  font-weight: 500;
  margin: 5px;
  font-size: 12px;
}

.modal-restau input,
.modal-restau textarea {
  width: 100%;
  padding: 2px 0px 2px 7px;
  font-size: 13px;
  line-height: 22px;
  color: #000;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 5px;
  margin-top: 0px;
  background-color: rgba(217, 217, 217, 0.69);
  font-family: "Poppins";
  min-height: unset;
  resize: vertical;
}

.modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
  border: none;
  background-color: #003671;
}

.restau-modal-btn {
  width: 40% !important;
  align-self: center;
}

/* Add Date */

.modal-restau-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 40vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
}

.modal-restau-add h1 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: 0;
  border-bottom: 0.5px groove rgba(0, 0, 0, 0.2);
}

.modal-restau-tables {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 85vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  outline: none;
}

.modal-restau-tables h1 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: 0;
}

/* .restau-table-container {
  border: 1px solid darkgray;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(217, 217, 217, 0.2);
} */

.restau-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.restau-table-row h4 {
  font-weight: 500;
  font-size: 14px;
  margin: 5px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.7);
}

.modal-restau-add .text-restau {
  width: 98%;
  padding: 2px 0px 2px 7px;
  font-size: 15px;
  /* font-weight: 600; */
  line-height: 30px;
  color: #000;
  /* opacity: 0.8; */
  border: 1px #636363 solid;
  border-radius: 5px;
  margin-top: 10px;
}

.pa-form input,
.pa-form input:focus-visible {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #0b4b92;
  border-radius: unset;
  width: 40%;
  outline: unset;
  line-height: 15px;
  margin-left: 10px;
}

.pa-form {
  width: 40% !important;
}

.pa-form-row {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.pa-form h2 {
  margin-bottom: 0;
  font-weight: 600;
}

.pa-form label {
  font-size: 12px;
}

.pa-form textarea {
  /* height: fit-content; */
  width: 90%;
  background-color: #fff;
  border: none;
  line-height: 15px;
  padding: 0 20px;
}

.pa-form .radio-restau {
  justify-content: space-evenly;
  height: 40px;
  margin-top: 10px;
}
.pa-form .radio-restau span {
  font-size: 12px;
}

.pa-form .restau-modal-btn {
  padding: 10px;
  margin-top: 10px;
  border: none;
}

.modal-restau-add input[type="date"],
.modal-restau-add input[type="time"] {
  font-size: 15px;
  font-family: "Poppins";
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
}

@media (max-width: 650px) {
  .modal-restau-add {
    width: 77%;
  }

  .modal-restau {
    width: 70%;
    height: 70%;
  }

  .modal-restau-tables {
    width: 77%;
  }
}
