.cartes_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.cartes_table{
    width: 100%;
    padding: 0 10vw 0 10vw;
}

.cartes_table table{
    width: 100%;
    background-color: white;
    border-radius: 0 0 13px 13px;
    border-collapse: collapse;
    font-size: 0.8vw;
}

.cartes_table tr:first-child td{
    font-weight: 600;
}

.cartes_table tr{
    cursor: pointer;
    color: rgb(119, 119, 119);
}

.cartes_table tr:first-child, .cartes_table tr:first-child:hover{
    cursor: auto;
    color: #0B4B92;
    background-color: white;
}

.cartes_table tr:hover{
    background-color: #9bbbe0;
    color: white;
}

.cartes_table td{
    padding: 2vh 3vw 2vh 3vw;
    border-bottom: 1px rgb(230, 230, 230) solid;
    text-align: center;
}


.cartes_table tr:last-child td{
    border-bottom: none;
}

.cartes_table details{
    width: 100%;
}

.cartes_table .details td, .cartes_table .details tr:first-child td, .cartes_table .details tr:hover{
    color: #0B4B92;
    font-weight: 500;
    cursor: auto;
    background-color: white;
    text-align: left;
    padding: 1vh 3vw 1vh 3vw;
}

.cartes_table .details p{
    margin: 0;
}

.cartes-form-overlay, .show-carte-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.cartes-form-container, .show-carte-container {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}




