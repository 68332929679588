.MssAdmin {
    padding: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
}
.MssAdminPanelUsers {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.MssAdminPanelUsersRow {
    display: flex;
    gap: 30px;
}
.MssAdminPanelUsersRowColab {
    flex-grow: 1;
}
.MssAdminPanelUsersRowItem {
    width: 306px;
}
.MssAdminPanelAuthorisation {
    width: 471px;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
}
.MssAdminPanelAuthorisationHeader {
    width: calc(100% - 40px);
    height: 50px;
    background-color: #A0E295;
    border-radius: 10px 10px 0 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.MssAdminPanelAuthorisationHeaderTitle {
    display: flex;
    gap: 10px;
}
.MssAdminPanelAuthorisationHeaderTitleLabel {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #295721;
    user-select: none;
}
.MssAdminPanelAuthorisationHeaderUser {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
}
.MssAdminPanelAuthorisationHeaderUserInfo {
    display: flex;
    flex-direction: column;
}
.MssAdminPanelAuthorisationHeaderUserInfoName {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #295721;
    font-size: 12px;
    user-select: none;
    text-align: right;
}
.MssAdminPanelAuthorisationHeaderUserInfoRole {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    user-select: none;
    text-align: right;
}
.MssAdminPanelAuthorisationHeaderUserPicture {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-size: 40px 40px;
    background-color: #fff;
}
.MssAdminPanelAuthorisationBody {
    width: calc(100% - 40px);
    height: calc(100vh - 520px);
    border-radius: 0 0 10px 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
}
.AutorisationDropdownSection {
    display: flex;
    flex-direction: column;
}
.AutorisationDropdownHeader {
    display: flex;
    height: 20px;
    background-color: #F4F4F4;
    padding: 10px;
    border-radius: 5px;
}
.AutorisationDropdownHeaderTitle {
    flex-grow: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,54,113,0.8);
    user-select: none;
}
.AutorisationDropdownContent {
    display: flex;
    flex-direction: column;
}
.AutorisationDropdownContentAutorisations {
    display: flex;
    flex-direction: column;
}
.AutorisationDropdownContentAutorisation {
    width: 100%;
    display: flex;
    padding-top: 20px;
}
.AutorisationDropdownContentAutorisationChild {
    width: 100%;
    display: flex;
    padding-top: 20px;
    border-left: 1px solid #cbcbcb;
}
.AutorisationDropdownContentAutorisationName {
    flex-grow: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,0,0,0.8);
    padding-left: 20px;
    cursor: default;
    user-select: none;
}

.AutorisationDropdownContentAutorisationFamilyList {
    display: flex;
    flex-direction: column; 
}
.AutorisationDropdownContentAutorisationFamilyWrapper {
    display: flex;
    flex-direction: column;
}
.AutorisationDropdownContentAutorisationFamily {
    width: 100%;
    display: flex;
    padding-top: 20px;
}
.AutorisationDropdownContentAutorisationFamilyName {
    flex-grow: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,0,0,0.8);
    cursor: default;
    user-select: none;
}