.MssAdminLicence {
    width: calc(100% - 40px);
    height: calc(70px - 40px);
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.MssAdminLicenceType {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,54,113,0.8);
}
.MssAdminLicenceUpdate {
    display: flex;
    gap: 10px;
}
.MssAdminLicenceUpdateInfo {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    color: rgba(0,0,0,0.5);
}
.MssAdminLicenceButton {
    width: 162px;
    height: 30px;
    background-color: #0B4B92;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: rgba(255,255,255,.8);
    user-select: none;
}