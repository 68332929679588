.modal-restau-historique {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 85vh;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  outline: none;
}

.modal-restau-historique h1 {
  font-size: 20px;
}

.historique-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.historique-body {
  min-height: 300px;
  max-height: 100%;
  overflow-y: auto;
}

.historique-body-header {
  display: flex;
  justify-content: space-between;
}

.historique-body-header h2 {
  font-size: 16px;
  font-weight: 600;
}

.historique-body-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  background-color: #e9ecfa;
  border-radius: 10px;
  margin-bottom: 5px;
}

.historique-mobile-body-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 7px;
  background-color: #e9ecfa;
  border-radius: 10px;
  margin-bottom: 5px;
}

.historique-mobile-body-row div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.historique-body-row h2,
.historique-mobile-body-row h2 {
  font-size: 14px;
  font-weight: 500;
}

.historique-mobile-body-row h2 {
  margin: 0 5px;
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.historique-mobile-body-row h3 {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0 5px;
  margin-bottom: 5px;
}

.historique-mobile-body-row h4 {
  color: rgba(0, 0, 0, 0.6);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0 5px;
  margin-top: 5px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-family: poppins !important;
}

.historique-filter input[type="date"] {
  font-size: 16px;
  font-family: "Poppins";
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}

.historique-ADD {
  background-color: #e9faf6;
}

.historique-DELETE {
  background-color: #fae9e9;
}

.historique-body-header h2,
.historique-body-row h2 {
  margin-right: 10px;
  min-width: 100px;
}

@media (max-width: 1000px) {
  .historique-body-row {
    width: fit-content;
  }
}

@media (max-width: 620px) {
  .historique-filter {
    flex-direction: column;
    justify-content: flex-start;
  }
  .modal-restau-historique {
    overflow-y: unset;
    width: 85%;
    padding: 10px;
  }
  .historique-body {
    min-height: 200px;
  }
}

/* Mobile component */

.historique-mobile-filter input[type="date"] {
  font-family: "Poppins";
  width: 100%;
}

.historique-mobile-filter-row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.historique-mobile-filter-row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.historique-mobile-filter-row2 button {
  display: flex;
  flex-direction: row;
  padding: 3px 10px;
  align-items: center;
  border: none;
  border-radius: 20px;
  background: rgba(217, 217, 217, 0.55);
  font-family: "Poppins";
  cursor: pointer;
  margin-left: 5px;
}

.historique-mobile-filter-row2 h3 {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.69px;
  width: max-content;
}

.historique-mobile-filter-row3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.historique-mobile-filter-row3 div {
  /* width: max-content; */
  min-width: 30%;
}
