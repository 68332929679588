.formAnullerFeed{
    width: 460px;
    padding: 5vh 8vh 5vh 8vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 25%;
    outline: 0;
    right: 30%;
    background-color: white;
}
.FormActionsCenter{
    margin-left: 40%;
}

.DivInputStyle{

   padding: 2%;
}

.InputStyle{

    border-style: none;  
    border-radius: 6px; 
    text-align: center;  
    width: 400px;
    height: 30px;    
    border: 2px solid rgba(41, 45, 50, 0.2);
}