.feed{  
   width: 80%;
   height: 100%;
   display: flex;
   flex-direction: column;
}

.FeedbackTopActions{
    padding-bottom: 2%;
    padding-top: 2%;  
    float: left;

 }
 
 .FeedbackTopActionsh3{
    padding-right: 10%;
    margin: 0%;    
 }

 .FeedbackInputsyle{ 
    border-style: none;  
    border-radius: 6px; 
    text-align: center;  
    width: 200px;
    height: 45px;    
    border: 2px solid rgba(41, 45, 50, 0.2);
 }


 .FeedbackDatasyle{
    width: 81px;
    height: 45px;  
    border: 2px solid rgba(41, 45, 50, 0.2);
    font-family: 'Montserrat';
    font-size: 12px; 
 }

 .FeedbackSelectSyle{
    width: auto;   
    min-height: 30px;
    height: 50px;  
    border-style: none;     
    border: 2px solid rgba(41, 45, 50, 0.2);
    background-color: white;
 }

 .FeedbackSelectSyleDiv{      
    border-style: none;  
    display: flex;
    padding-left: 0.5%;
    margin-top: -2.5%;
    float: right;   
 } 

 .DatasyleFeddback{
   border-style: none;  
   border-radius: 6px; 
   text-align: center;  
   width: 120px;
   height: 30px;    
   border: 2px solid rgba(41, 45, 50, 0.2);   
}