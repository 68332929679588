.userPreference{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8vh;
}

.userPreference_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.btnstylePreference{
    margin-left: 4%;
    height: 35px;
}

.userPreference_content{    
    background-color: white;
    padding: 8vh 10vh 2vh 10vh;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}

.userPreference_info{
    display: inline-block;
    background-color: gainsboro;
    width: 100%;
    padding-left: 5%;
    padding-bottom: 3%;
}

.userPreference_details{
    padding: 0vh 0vh 5vh 5vh;
    border-bottom: inset;
}

.userPreference_photo{
    padding: 0vh 0vh 0vh 0vh;
    border-right: inset;
    border-bottom: inset;
    width: 25%;
}

.ProfilClientsPreferenceTopActions{
    padding-bottom: 2%;
    padding-left: 0%;
    padding-top: 0%;
    display: -webkit-box;
 }

.userPreference_tags{
    display: inline-flex;
    position: absolute;
    bottom: 0;
}
._tagsPreference{
    display: inline-flex;
}

.user_tagsPreference{
      
    margin-left: 0%;
    margin-right: 8%;
}

.userPreference_element{
  display: inline-block;
  width: 130px;
}

.moreContentPreference{
    /* bottom: 0; */
    display: block;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.moreContent1Preference{
    /* bottom: 0; */
    width: 100%;
}


.moreContent_stylePreference{
    display: flex;
}

.userPreference_payment .card_elementPreference{
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 0.8vw;
    cursor: pointer; 

}

.DataDetailsSylePreference{
    box-sizing: border-box;
    width: 160px;
    height: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
 }

 .selectSylePreference{  
    width: 150px;
    min-height: 25px;
    height: 38px;
    border-style: none;  
    margin-right: 1%;      
    border: 2px solid rgba(41, 45, 50, 0.2);
 }

 .selectSyleDivPreference{      
    border-style: none;  
    display: flex;
    padding-left: 0.5%;
 }
 
 .ProfilClientsPreferenceTopActionsh3{
    padding-left: 18%;
    padding-right: 5%;
    margin: 0%;    
 }

 .userPreference_Items{

    padding-top: 2%;
 }
 .ClientPreferencesBtn{
    margin-top: 2%;
 }
 .TagImg{
    height: 15px;
    margin-left: 1%;
}
.TagsLine{
    display: ruby;
    width: 153px;
    margin-left: 2%;
}