.booking_payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 8vh;
}

.payment_header{
    background-color: #1D62AF;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0 50px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.payment_content{
    background-color: white;
    padding: 2vh 0 2vh 0;
    margin: 0 50px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.booking_payment .card_element{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 0.8vw;
    cursor: pointer;

}

.booking_payment .card_image{
    width: 3vw;
}