.grey-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  line-height: 0.001; /* Adjust the line-height value as per your requirement */
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.card-title {
  color: #333;
  font-size: 1rem;
  margin-right: 10px; 
  font-weight: bold;
}

.card-content {
  color: #666;
  font-size: 1rem;
}




