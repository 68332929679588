.userDetails_payment{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    width: 80%;
    margin-bottom: 8vh;
    padding: 0 0 0 10vw;
}

.userDetails_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.TagLogo{
    padding: 4vh 3vh 4vh 0vh;
}

.btnstyle{
    margin-left: 4%;
    height: 35px;
}

.userDetails_content{    
    background-color: white;
    padding: 8vh 10vh 2vh 10vh;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}

.userDetails_info{
    display: inline-flex;
    border-style: groove;
    background-color: white;
    width: 100%;
    /* padding-bottom: 5%; */
}

.userDetails_details{
    padding: 0vh 0vh 5vh 5vh;
}

.userDetails_detailsCc{
    width: 100%;
}
.userDetails_photo{
    padding: 0vh 0vh 0vh 0vh;
    border-right: inset;
    width: 25%;
}

.ProfilClientsDetailsTopActions{
    padding-bottom: 2%;
    padding-left: 0%;
    padding-top: 0%;
    display: -webkit-box;
 }

.userDetails_tags{
    display: inline-flex;
    position: absolute;
    bottom: 0;
}
._tags{
    display: inline-flex;
}

.user_tags{
      
    margin-left: 0%;
    margin-right: 8%;
}

.userDetails_element{
  display: inline-block;
  width: 130px;
}

.userDetails_CreditCard{
    display: inline-block;
    padding-left: 3%;
  }

.moreContent{
    /* bottom: 0; */
    display: block;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.moreContent1{
    /* bottom: 0; */
    width: 100%;
}


.moreContent_style{
    display: flex;
}

.user_payment .card_element{
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 0.8vw;
    cursor: pointer; 

}

.DataDetailsSyle{
    box-sizing: border-box;
    width: 160px;
    height: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
 }

 .selectSyle{  
    width: 150px;
    min-height: 25px;
    height: 38px;
    border-style: none;  
    margin-right: 1%;      
    border: 2px solid rgba(41, 45, 50, 0.2);
 }

 .selectSyleDiv{      
    border-style: none;  
    display: flex;
    padding-left: 0.5%;
 }
 
 .ProfilClientsDetailsTopActionsh3{
    padding-left: 18%;
    padding-right: 5%;
    margin: 0%;    
 }
 .user_statoName{
    color: blue;    
    text-decoration: underline;
    font-size: 11px;
 }
 .TagsName{
    border-radius: 15px;
    background-color: #1D62AF;  
    color: white;  
    padding-left: 3%;
    padding-right: 3%;
    margin-right: 3%
 }
 .TagsLine{
    display: ruby;
    width: 153px;
    font-size: small;
    margin-top: 3%;
}
.TagImg{
    height: 15px;
    margin-left: 1%;
}