.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-section {
  display: flex;
  flex-direction: row;
  margin: 80px;
  margin-top: 0;
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  height: auto;
  flex-grow: 1;
}

.home-section-column {
  width: 50%;
}

.home-section h1 {
  color: #263238;
}

.home-section p {
  color: #263238;
  opacity: 0.8;
}

.home-hr {
  width: 100%;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .home-section {
    flex-direction: column-reverse;
    height: unset;
  }
  .home-section-column {
    width: 100%;
  }
}
