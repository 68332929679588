.PrimaryHeader {
    max-width: 1320px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.PrimaryHeaderLogo {
    user-select: none;
}
.PrimaryHeaderTitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #1D62AF;
    padding: 0 20px;

    user-select: none;
}
.PrimaryHeaderSeparator {
    height: 1px;
    opacity: 0.6;
    background-color: #B1B1B1;
    flex-grow: 1;
}