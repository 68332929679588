.ProfilClientFormActions{
    display: flex;
    align-self: center;
    margin-top: auto;
}

.ProfilClientButtonTout{
    margin-right: 10px;
    background-color: white;
    color: black;
    border: 1px groove black;
}