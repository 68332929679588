.Layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Outlet {
    display: flex;
    width: 100vw;
    min-height: calc(100vh - 70px - 140px - 140px);
    /* max-height: calc(100vh - 70px - 140px - 140px); */
    justify-content: center;
}