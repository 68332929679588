.TopNav {
    z-index: 1;
    width: 100%;
    height: 70px;
    background-color: #0B4B92;
    display: flex;
    justify-content: center;
}
.TopNavWrapper {
    margin: 0 20px;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.TopNavLogo {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.TopNavLogoImage {
    width: 50px;
    height: 50px;
    background-size: cover;
}
.TopNavAppName {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}
.TopNavDate {
    display: flex;
    justify-content: center;
    align-items: center;
}
.TopNavDateLeft {
    cursor: pointer;
}
.TopNavDate .MuiInputBase-root {
    padding: 0;
}
.TopNavDate .MuiInputBase-input {
    height: 30px;
    background-color: #235d9d !important;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 0;
    text-transform: capitalize;
    caret-color: transparent;
    text-align: center;
    cursor: pointer;
}
.TopNavDate .MuiInputAdornment-root {
    display: none;
}
.TopNavDate fieldset {
    display: none;
}
.TopNavDateRight {
    cursor: pointer;
}
.TopNavUser {
    display: flex;
    align-items: center;
}
.TopNavUserNotification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #235d9d;
    border-radius: 50px;
    cursor: pointer;
}
.TopNavUserNotificationMenuBackdrop {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
}
.TopNavUserNotificationMenu {
    position: absolute;
    top: 80px;
    margin-right: -211px;
    background-color: #fff;
    width: 170px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 20px;
}
.TopNavUserNotificationMenuItem {
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #0B4B92;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    padding: 10px;
}
.TopNavUserNotificationMenuItem:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
.TopNavUserProfile {
    display: flex;
    cursor: pointer;
    margin-left: 15px;
}
.TopNavUserProfileImage {
    width: 50px;
    height: 50px;
    background-size: cover;
    user-select: none;
}
.TopNavUserProfileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
.TopNavUserProfileInfoName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 20px;
    color: #EAF4FF;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}
.TopNavUserProfileInfoRole {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 20px;
    color: rgba(255, 255, 255, 0.58);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}
.TopNavUserProfileInfoMenuBackdrop {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
}
.TopNavUserProfileInfoMenu {
    position: absolute;
    top: 80px;
    margin-right: 86px;
    background-color: #fff;
    width: 170px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 20px;
}
.TopNavUserProfileInfoMenuItem {
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #0B4B92;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    padding: 10px;
}
.TopNavUserProfileInfoMenuItem:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
@media (max-width: 768px) {
    .TopNavAppName {
        display: none;
    }
    .TopNavUserProfileInfoName {
        display: none;
    }
    .TopNavUserProfileInfoRole {
        display: none;
    }
    .TopNavUserProfileInfo {
        margin-left: 0;
    }
    .TopNavUserNotificationMenu {
        margin-right: 20px;
    }
    .TopNavUserProfileInfoMenu {
        margin-right: 190px;
    }
    .TopNavUserNotificationMenuItemMobile {
        color: #0B4B92;
    }
}
