.user_payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 8vh;
    padding: 0 0 0 0vw;
}

.user_header{
    background-color: #0B4B92;
    color: rgba(255,255,255,.48);
    padding: 2vh 3vw 2vh 3vw;
    margin: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-size: 1vw;
}

.user_content{
    background-color: white;
    padding: 8vh 10vh 2vh 10vh;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}

.user_element{
    padding: 0vh 0vh 3vh 5vh;
}

.user_payment .card_element{
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 0.8vw;
    cursor: pointer; 
}

.user_payment .card_image{
    width: 3vw;
}

.user_chambre{
    font-size: 11px;
    font-weight: bold;    
}

.user_nome{
    font-size: 11px;   
}

.user_info{
    font-size: 9px;  
    margin-top: -3%;
}
.statoDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2vh;
    padding: 0 0 0 0vw;
}