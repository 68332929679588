html {
    font-family: Montserrat;
    color: #141823;
}

/* label */
.FontLabel{
font-family: Montserrat;
font-size: 11px;
font-weight: 600;
line-height: 15px;
letter-spacing: -0.015em;
}

.FontLabel2{
font-family: Montserrat;
font-size: 11px;
font-weight: 600;
line-height: 15px;
letter-spacing: -0.015em;
display: inline-block;
}

/* input */
.StyleInput{
border-radius: 5px;
border: 0.6px solid rgba(0, 0, 0, 0.4);
background-color : white; 
}

.titleCard {
color: #1D62AF;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 36px; /* 180% */
letter-spacing: -0.3px;
} 

.SubTitleCard{
color: #141823;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.21px;   
}

.line {
padding-bottom: 5px;
border-bottom-style: solid;
border-bottom-width: 2px;  
opacity: 0.5;
color: rgba(0, 0, 0, 0.20);
}

.styleLink{
color: #1D62AF;
text-align: center;
font-family: Montserrat;
font-size: 8px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.18px;
text-decoration-line: underline;
}

.btn{
    transform: translate(35%, 20%);
}

.service-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2% 5%;
}

.service-container h1 {
  color: #b3b3b3;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.69px;
}

.service-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.service-filter fieldset {
  border: none;
}

.service-filter-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.service-reservation-btn {
  background-color: #a0e295;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #1d62af;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
}

.service-filtre-btn {
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(71, 70, 70);
  cursor: pointer;
}

.service-body {
  /* padding: 30px 80px; */
  background-color: #fff;
  border-radius: 10px;
}

.service-body-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1d62af;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0 10px;
  color: #fff;
}

.service-body-header h2 {
  font-size: 15px;
  font-weight: 500;
}

.service-body-table {
  display: flex;
  flex-direction: column;
}

.service-body-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 40px;
}

.service-body-table-header h3 {
  font-size: 15px;
  font-weight: 500;
}

.service-body-table-row {
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin: 0 15px 10px 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 5px;
}

.service-body-table-row h3 {
  font-size: 14px;
  font-weight: 500;
}

.service-body-footer {
  margin-top: 20px;
}

.service-body-footer button {
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}

.service-body-table-row-details {
  color: #1d62af;
  padding: 0 30px;
}

.service-body-table-row-details h3 {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 0.5px groove #b3b3b3;
}

.service-body-table-row-details button {
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  background-color: #a0e295;
  color: #1d62af;
  font-family: "Poppins", sans-serif !important;
}

@media (max-width: 650px) {
  .service-filter {
    flex-direction: column;
  }
  .service-filter-right {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
